const polished = require('polished')

const COLOR_MAX_INTENSITY = 256
const WHITE = '#fff'
const LUMINANCE_THREESHOLD = 0.6
const SIMILARITY_THREESHOLD = 0.001

function generateRandomColor() {
  const red = Math.floor(Math.random() * COLOR_MAX_INTENSITY)
  const green = Math.floor(Math.random() * COLOR_MAX_INTENSITY)
  const blue = Math.floor(Math.random() * COLOR_MAX_INTENSITY)
  return `#${((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1)}`
}

function areColorsVisuallySimilar(color1, color2) {
  const color1Luminance = polished.getLuminance(color1)
  const color2Luminance = polished.getLuminance(color2)
  return Math.abs(color1Luminance - color2Luminance) < SIMILARITY_THREESHOLD
}

function generateColors({ baseColorsSet = new Set(), numberOfColors = 0 }) {
  const newColors = Array.from(baseColorsSet)

  if (newColors.length >= numberOfColors) return newColors

  const randomColor = generateRandomColor()
  const lighteningFactor = Math.random() * 0.5
  const lightenColor = polished.lighten(lighteningFactor, randomColor)
  const luminance = polished.getLuminance(lightenColor)

  const isEnoughLigth = luminance > LUMINANCE_THREESHOLD
  const isEnoughDifferent = !newColors.some((storedColor) => areColorsVisuallySimilar(storedColor, lightenColor))
  const isNotWhitish = !areColorsVisuallySimilar(WHITE, lightenColor)
  const shouldAddColor = isEnoughLigth && isEnoughDifferent && isNotWhitish

  if (shouldAddColor) baseColorsSet.add(lightenColor)
  return generateColors({ baseColorsSet, numberOfColors })
}

export function generateCategoryColor() {
  const [color] = generateColors({ numberOfColors: 1 })
  return color
}
