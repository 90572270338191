import React, { useState, useEffect } from 'react'
import PropTypes from 'helpers/proptypes'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHasLoadingSucceeded } from 'hooks'
import { Card, List } from 'semantic-ui-react'
import { FormActionsButtons } from './FormActionsButtons'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { toast } from 'react-toastify'
import { getForm } from 'redux/forms/reducer'
import { updatingTag, getUpdateTagError, getCreateTagError, getCategory } from 'redux/entities/selectors'
import { withRouter } from 'react-router'
import { useKebabCase } from 'hooks/useKebabCase'
import './Card.css'

function ActionButtonsWrapper({ children, ...props }) {
  return (
    <Card.Content {...props}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        {children}
      </div>
    </Card.Content>
  )
}

ActionButtonsWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

function _TagCard({ router, tag }) {
  const { t } = useTranslation()

  const [createdTagId, setCreatedTagId] = useState()

  const form = useSelector((state) => getForm(state, 'tag'))
  const getCategoryById = useSelector((state) => (id) => getCategory(state, { id }))

  const createError = useSelector(getCreateTagError)
  const isUpdatingTag = useSelector((state) => updatingTag(state, tag.id))
  const updateError = useSelector((state) => getUpdateTagError(state, tag.id))
  const isTagUpdated = useHasLoadingSucceeded(isUpdatingTag, updateError)

  const { truncatedFormattedText: title } = useKebabCase(form.get('title', ''), 191)

  useEffect(() => {
    if (createdTagId)
      toast.success(`${t("ToastNotification::Well done! You've just saved the tag")} 👍`, {
        type: toast.TYPE.SUCCESS,
        onClose: () => router.push(`/tags/edit/${createdTagId}`),
      })
  }, [createdTagId, router, t])

  useEffect(() => {
    if (isTagUpdated)
      toast.success(
        `${t(`ToastNotification::Well done! You've just updated the tag "{{title}}"`, { title: tag.title })} 👍`,
        { type: toast.TYPE.SUCCESS },
      )
  }, [isTagUpdated, t, tag.title])

  return (
    <Card className='Tag-Card'>
      <Card.Content>
        <Card.Header>{t('Tags::Summary::Tag preview')}</Card.Header>
        <List bulleted>
          <List.Item>{t('Tags::Summary::Title: {{title}}', { title })}</List.Item>

          <List.Item>
            {t('Tags::Summary::Category: {{category}}', {
              category: getCategoryById(form.get('category_id')).name,
            })}
          </List.Item>

          <List.Item>
            {t('Tags::Summary::Description: {{description}}', {
              description: form.get('description'),
            })}
          </List.Item>
        </List>
      </Card.Content>

      <FormActionsButtons tag={tag} onTagCreation={setCreatedTagId} Wrapper={ActionButtonsWrapper} />

      {(createError || updateError) && (
        <Card.Content>
          <ApiErrorMessage error={createError || updateError} />
        </Card.Content>
      )}
    </Card>
  )
}

_TagCard.propTypes = {
  tag: PropTypes.immutable.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

_TagCard.defaultProps = {
  tag: undefined,
}

export const TagCard = withRouter(_TagCard)
