import { useMemo } from 'react'
import { kebabCase } from 'lodash'

export function useKebabCase(text = '', maxLength = 191) {
  const formattedText = kebabCase(text)

  const isTextInKebabCase = useMemo(() => {
    if (typeof text === 'undefined' || text.length === 0) return false
    return formattedText !== text
  }, [formattedText, text])

  const truncatedFormattedText = formattedText.slice(0, maxLength)

  return {
    formattedText,
    truncatedFormattedText,
    isTextInKebabCase,
  }
}
