import {
  getEntities,
  getCreatingError,
  getFetchingError,
  getUpdatingError,
  getDeletingError,
  isFetching,
  getEntity,
  getEntityCount,
  getEntityTotal,
  getEntityOrder,
  isUpdating,
  isCreating,
  isDeleting,
} from './_utils'

export const getTags = getEntities('tags')
export const getTag = getEntity('tags')
export const getTagsCount = getEntityCount('tags')
export const getTagsOrder = getEntityOrder('tags')
export const getTagsTotal = getEntityTotal('tags')
export const getTagsList = (state) => getTagsOrder(state).map((id) => getTag(state, { id }))
export const getSortedTags = (state) =>
  getTags(state)
    .toList()
    .sortBy((tag) => tag.title)

export const fetchingTags = isFetching('/api/tags')
export const fetchingTag = (state, id) => isFetching(`/api/tags/${id}`)(state)
export const creatingTag = isCreating('/api/tags')
export const updatingTag = (state, id) => isUpdating(`/api/tags/${id}`)(state)
export const archivingTag = (state, id) => isDeleting(`/api/tags/${id}`)(state)
export const unarchivingTag = (state, id) => isUpdating(`/api/tags/${id}/unarchive`)(state)

export const getCreateTagError = (state) => getCreatingError(`/api/tags`)(state)
export const getUpdateTagError = (state, id) => getUpdatingError(`/api/tags/${id}`)(state)
export const getFetchTagsError = (state) => getFetchingError('/api/tags')(state)
export const getArchiveTagError = (state, id) => getDeletingError(`/api/tags/${id}`)(state)
export const getUnarchiveTagError = (state, id) => getUpdatingError(`/api/tags/${id}/unarchive`)(state)
