import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFetchPaginatedResults } from '../../hooks/pagination/useFetchPaginatedResults'
import { List, Paginate } from 'components/lists'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { TagsRow } from './TagsRow'
import { getLocation } from 'redux/reducers/router'
import { getTagsList, getFetchTagsError, fetchingTags, getTagsTotal } from 'redux/entities/selectors'

export function TagsList() {
  const { t } = useTranslation()
  useFetchPaginatedResults('tag') // fetch tags to retrieve metadata

  const error = useSelector(getFetchTagsError)
  const loading = useSelector(fetchingTags)
  const tags = useSelector(getTagsList)
  const total = useSelector(getTagsTotal)
  const location = useSelector(getLocation)

  const headers = useMemo(
    () => [
      { label: t('Tags::Id'), field: 'id' },
      { label: t('Tags::Category'), field: 'category' },
      { label: t('Tags::Title'), field: 'title' },
      { label: t('Tags::Archived'), field: 'archived_at' },
      { label: t('Tags::Actions') },
    ],
    [t],
  )

  return (
    <Paginate count={total} previous next loading={loading}>
      <List striped celled sortable collapsing headers={headers} location={location}>
        {tags.toList().map((tag) => (
          <TagsRow key={tag.id} tag={tag} />
        ))}
      </List>
      <ApiErrorMessage error={error} modal />
    </Paginate>
  )
}
