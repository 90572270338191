import { businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const categoriesActions = new ActionsCreator({ Api: businessApi, actionTypesPrefix: 'categories' })
export const fetchCategories = ({ query } = {}) =>
  categoriesActions.fetch({
    url: '/api/categories',
    schema: { categories: [schemas.categories] },
    query,
  })

export const fetchCategory = (id) =>
  categoriesActions.fetch({
    url: `/api/categories/${id}`,
    schema: { category: schemas.categories },
  })

export const archiveCategory = (id) =>
  categoriesActions.delete({
    url: `/api/categories/${id}`,
  })

export const unarchiveCategory = (id) =>
  categoriesActions.update({
    url: `/api/categories/${id}/unarchive`,
    schema: { category: schemas.categories },
  })

export const createCategory = (payload) =>
  categoriesActions.create({
    url: `/api/categories`,
    payload,
    schema: { category: schemas.categories },
  })

export const updateCategory = (id, payload) =>
  categoriesActions.update({
    url: `/api/categories/${id}`,
    payload,
    schema: { category: schemas.categories },
  })
