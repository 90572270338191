import React, { useEffect } from 'react'
import PropTypes from 'helpers/proptypes'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Header, Form, ButtonGroup, Button, ButtonOr, Message, Grid } from 'semantic-ui-react'
import { TagsSection } from './TagsSection'
import { KebabCaseFormatMessage } from 'components/forms/categories'
import { createForm, removeForm, mergeInForm } from 'redux/forms/actions'
import { getForm } from 'redux/forms/reducer'
import { konstants } from '@vizeat/helpers'
import { generateCategoryColor } from 'helpers/colors'

const visibilityOptions = Object.values(konstants.CATEGORY_VISIBILITIES).map((visibility) => ({
  value: visibility,
  text: visibility,
}))

function FormFieldLabel({ children, required }) {
  return (
    <Header as='h3' color='grey' dividing style={{ borderBottomWidth: '1px' }}>
      {children} {required && '*'}
    </Header>
  )
}

FormFieldLabel.propTypes = {
  children: PropTypes.node.isRequired,
  required: PropTypes.bool,
}

FormFieldLabel.defaultProps = {
  required: false,
}

export function CategoryForm({ category }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const form = useSelector((state) => getForm(state, 'category'))

  useEffect(() => {
    dispatch(
      createForm({
        formName: 'category',
        initialState: {
          name: category.name || undefined,
          visibility: category.visibility || konstants.CATEGORY_VISIBILITIES.PUBLIC,
          description: category.description || undefined,
          color: category.color || generateCategoryColor(),
          readonly: category.readonly || false,
        },
      }),
    )
    return () => dispatch(removeForm({ formName: 'category' }))
  }, [category.color, category.description, category.name, category.readonly, category.visibility, dispatch])

  function handleChange(_, { value, name }) {
    dispatch(mergeInForm({ formName: 'category', value: { [name]: value } }))
  }

  return (
    <Form>
      <section id='name'>
        <FormFieldLabel required>{t('Category::Summary::Name')}</FormFieldLabel>
        <Form.Group>
          <Form.Field width={6}>
            <Form.Input name='name' value={form.get('name', '')} onChange={handleChange} />
          </Form.Field>
          <Form.Field width={10}>
            <KebabCaseFormatMessage text={form.get('name')} />
          </Form.Field>
        </Form.Group>
      </section>
      <section id='visibility'>
        <FormFieldLabel required>{t('Category::Summary::Visibility')}</FormFieldLabel>
        <Form.Group>
          <Form.Field width={6}>
            <Form.Select
              name='visibility'
              value={form.get('visibility', '')}
              onChange={handleChange}
              search
              options={visibilityOptions}
            />
          </Form.Field>
        </Form.Group>
      </section>

      <section id='readonly'>
        <FormFieldLabel>{t('Category::Summary::Type')}</FormFieldLabel>
        <Grid>
          <Grid.Row>
            <Grid.Column width={6}>
              <Form.Field>
                <Form.Checkbox
                  radio
                  label={t('Category::Summary::Immutable')}
                  name='checkboxRadioGroup'
                  value='readonly'
                  checked={form.get('readonly', false)}
                  onChange={(e) => e.preventDefault()}
                />
              </Form.Field>
              <Form.Field>
                <Form.Checkbox
                  radio
                  label={t('Category::Summary::Mutable')}
                  name='checkboxRadioGroup'
                  value='readonly'
                  checked={!form.get('readonly', false)}
                  onChange={(e) => e.preventDefault()}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={10}>
              <Message
                warning
                visible
                icon='exclamation circle'
                content={t(
                  'Category::Summary::You do not have the rights to change this value. Please contact a dev if you need to do it.',
                )}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </section>

      <section id='color'>
        <FormFieldLabel required>{t('Category::Summary::Color')}</FormFieldLabel>
        <ButtonGroup size='medium' style={{ marginBottom: '14px' }}>
          <Button style={{ backgroundColor: form.get('color') }} />
          <ButtonOr />
          <Button
            basic
            color='blue'
            onClick={(_) => handleChange(_, { value: generateCategoryColor(), name: 'color' })}
          >
            {t('Category::Summary::Pick another color')}
          </Button>
        </ButtonGroup>
      </section>

      <section id='description'>
        <FormFieldLabel required>{t('Category::Summary::Description')}</FormFieldLabel>
        <Form.TextArea
          name='description'
          value={form.get('description', '')}
          onChange={handleChange}
          style={{ marginBottom: '14px' }}
        />
      </section>

      <section id='tags'>
        <FormFieldLabel>{t('Category::Summary::Tags::Tags')}</FormFieldLabel>
        {category.id ? (
          <TagsSection category={category} />
        ) : (
          <Message
            info
            icon='info circle'
            content={t('Category::Summary::You will only be able to add tags after creating the color')}
          />
        )}
      </section>
    </Form>
  )
}

CategoryForm.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    visibility: PropTypes.string,
    description: PropTypes.string,
    readonly: PropTypes.boolean,
    color: PropTypes.string,
    tags: PropTypes.immutable.list,
  }).isRequired,
}
