import React, { useEffect } from 'react'
import PropTypes from 'helpers/proptypes'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button } from 'semantic-ui-react'
import { getForm } from 'redux/forms/reducer'
import { createTag, updateTag, archiveTag, unarchiveTag, fetchCategory } from 'redux/entities/actions'
import {
  creatingTag,
  getCreateTagError,
  updatingTag,
  getCategory,
  archivingTag,
  getArchiveTagError,
  unarchivingTag,
  getUnarchiveTagError,
} from 'redux/entities/selectors'
import { useKebabCase } from 'hooks/useKebabCase'
import { useHasLoadingSucceeded } from 'hooks'

export function FormActionsButtons({ onTagCreation, onTagUpdate, tag, Wrapper, fluidButtons }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const form = useSelector((state) => getForm(state, 'tag'))

  const { truncatedFormattedText: title } = useKebabCase(form.get('title', ''), 191)

  const category = useSelector((state) => getCategory(state, { id: tag.category_id }))
  const isCreatingTag = useSelector(creatingTag)
  const createTagError = useSelector(getCreateTagError)
  const isUpdatingTag = useSelector((state) => updatingTag(state, tag.id))
  const isArchivingTag = useSelector((state) => archivingTag(state, tag.id))
  const archiveTagError = useSelector((state) => getArchiveTagError(state, tag.id))
  const isUnarchivingTag = useSelector((state) => unarchivingTag(state, tag.id))
  const unarchiveTagError = useSelector((state) => getUnarchiveTagError(state, tag.id))
  const hasTagBeenCreated = useHasLoadingSucceeded(isCreatingTag, createTagError)
  const hasTagBeenArchived = useHasLoadingSucceeded(isArchivingTag, archiveTagError)
  const hasTagBeenUnarchived = useHasLoadingSucceeded(isUnarchivingTag, unarchiveTagError)

  useEffect(() => {
    if (hasTagBeenArchived || hasTagBeenUnarchived) dispatch(fetchCategory(category.id))
  }, [category.id, dispatch, hasTagBeenArchived, hasTagBeenCreated, hasTagBeenUnarchived])

  async function handleSave(e) {
    if (e) e.preventDefault()

    const basePayload = { title, description: form.get('description') || '' }

    if (tag.id) {
      const { payload } = await dispatch(
        updateTag(tag.id, { ...basePayload, category_id: form.get('category_id'), tag: { id: tag.id } }),
      )
      if (payload.error) return
      return onTagUpdate?.(tag.id)
    }

    const {
      payload: { data, error },
    } = await dispatch(createTag({ ...basePayload, category: { id: form.get('category_id') } }))
    if (!error) onTagCreation(data.result.tag)
  }

  if (!tag.id)
    return (
      <Wrapper>
        <Button primary fluid={fluidButtons} onClick={handleSave} loading={isCreatingTag}>
          {t('Tags::Summary::Save tag')}
        </Button>
      </Wrapper>
    )

  if (category.readonly) return null

  return (
    <Wrapper>
      {tag.archived_at ? (
        <Button
          color='green'
          fluid={fluidButtons}
          onClick={() => dispatch(unarchiveTag(tag.id))}
          loading={isArchivingTag}
        >
          {t('Tags::Summary::Unarchive')}
        </Button>
      ) : (
        <Button
          color='red'
          fluid={fluidButtons}
          inverted
          onClick={() => dispatch(archiveTag(tag.id))}
          loading={isUnarchivingTag}
        >
          {t('Tags::Summary::Archive')}
        </Button>
      )}

      <Button primary fluid={fluidButtons} onClick={handleSave} loading={isUpdatingTag}>
        {t('Tags::Summary::Update tag')}
      </Button>
    </Wrapper>
  )
}

FormActionsButtons.propTypes = {
  onTagCreation: PropTypes.func.isRequired,
  onTagUpdate: PropTypes.func,
  tag: PropTypes.shape({
    id: PropTypes.number,
    category_id: PropTypes.number,
    archived_at: PropTypes.string,
  }),
  Wrapper: PropTypes.node,
  fluidButtons: PropTypes.bool,
}

FormActionsButtons.defaultProps = {
  tag: undefined,
  onTagUpdate: undefined,
  Wrapper: React.Fragment,
  fluidButtons: true,
}
