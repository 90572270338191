import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import { useSelector, useDispatch } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { useHasLoadingSucceeded } from 'hooks'
import { Card, List, Button, Label, Icon } from 'semantic-ui-react'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { toast } from 'react-toastify'
import { getForm } from 'redux/forms/reducer'
import { createCategory, updateCategory, archiveCategory, unarchiveCategory } from 'redux/entities/actions'
import {
  creatingCategory,
  updatingCategory,
  getCategoryError,
  getCategoriesError,
  archivingCategory,
  unarchivingCategory,
  getSortedTags,
  getArchiveCategoryError,
} from 'redux/entities/selectors'
import { withRouter } from 'react-router'
import { useKebabCase } from 'hooks/useKebabCase'
import { CATEGORY_VISIBILITY_ICONS } from 'helpers/categories'

function ActionButtons({ onCategoryCreation, category, name }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const form = useSelector((state) => getForm(state, 'category'))
  const isCreatingCategory = useSelector(creatingCategory)
  const isUpdatingCategory = useSelector((state) => updatingCategory(state, category.id))
  const isArchivingCategory = useSelector((state) => archivingCategory(state, category.id))
  const isUnarchivingCategory = useSelector((state) => unarchivingCategory(state, category.id))

  async function handleSave(e) {
    if (e) e.preventDefault()
    const categoryPayload = {
      name,
      description: form.get('description'),
      color: form.get('color'),
      visibility: form.get('visibility'),
    }

    if (category.id) return dispatch(updateCategory(category.id, categoryPayload))

    const {
      payload: { data, error },
    } = await dispatch(createCategory(categoryPayload))
    if (!error) onCategoryCreation(data.result.category)
  }

  if (!category.id)
    return (
      <Card.Content textAlign='center'>
        <Button primary onClick={handleSave} loading={isCreatingCategory}>
          {t('Category::Summary::Save category')}
        </Button>
      </Card.Content>
    )

  if (category.readonly) return null

  return (
    <Card.Content textAlign='center'>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'center' }}>
        {category.archived_at ? (
          <Button
            fluid
            color='green'
            onClick={() => dispatch(unarchiveCategory(category.id))}
            loading={isArchivingCategory}
          >
            {t('Category::Summary::Unarchive')}
          </Button>
        ) : (
          <Button
            fluid
            color='red'
            inverted
            onClick={() => dispatch(archiveCategory(category.id))}
            loading={isUnarchivingCategory}
          >
            {t('Category::Summary::Archive')}
          </Button>
        )}

        <Button primary fluid onClick={handleSave} loading={isUpdatingCategory}>
          {t('Category::Summary::Update category')}
        </Button>
      </div>
    </Card.Content>
  )
}

ActionButtons.propTypes = {
  onCategoryCreation: PropTypes.func.isRequired,
  category: PropTypes.shape({
    id: PropTypes.number,
    readonly: PropTypes.bool,
    archived_at: PropTypes.string,
  }),
  name: PropTypes.string,
}

ActionButtons.defaultProps = {
  category: undefined,
  name: undefined,
  tags: undefined,
}

function _CategoryCard({ router, category }) {
  const { t } = useTranslation()

  const [createdCategoryId, setCreatedCategoryId] = useState()

  const form = useSelector((state) => getForm(state, 'category'))

  const sortedTags = useSelector(getSortedTags)
  const tags = useMemo(() => {
    const formTags = form.get('tags', [])
    if (!category.id && formTags.size === 0) return
    return sortedTags
      .filter((tag) => formTags.includes(tag.get('id')))
      .map((tag) => tag.get('title').trim())
      .toJS()
  }, [category.id, form, sortedTags])
  const createError = useSelector(getCategoriesError)
  const isUpdatingCategory = useSelector((state) => updatingCategory(state, category.id))
  const updateError = useSelector((state) => getCategoryError(state, category.id))
  const isCategoryUpdated = useHasLoadingSucceeded(isUpdatingCategory, updateError)
  const isArchivingCategory = useSelector((state) => archivingCategory(state, category.id))
  const archiveError = useSelector((state) => getArchiveCategoryError(state, category.id))
  const isCategoryArchived = useHasLoadingSucceeded(isArchivingCategory, archiveError)

  const { truncatedFormattedText: name } = useKebabCase(form.get('name', ''), 191)

  useEffect(() => {
    if (createdCategoryId)
      toast.success(`${t("ToastNotification::Well done! You've just saved the category")} 👍`, {
        type: toast.TYPE.SUCCESS,
        onClose: () => router.push(`/categories/edit/${createdCategoryId}`),
      })
  }, [createdCategoryId, router, t])

  useEffect(() => {
    if (isCategoryUpdated)
      toast.success(`${t(`ToastNotification::Well done! You've just updated the category "{{name}}"`, { name })} 👍`, {
        type: toast.TYPE.SUCCESS,
      })
  }, [isCategoryUpdated, t, name])

  useEffect(() => {
    if (isCategoryArchived)
      toast.success(`${t(`ToastNotification::Well done! You've just archived the category "{{name}}"`, { name })} 👍`, {
        type: toast.TYPE.SUCCESS,
        onClose: () => router.push(`/categories`),
      })
  }, [isCategoryArchived, t, name, router])

  return (
    <Card>
      <Card.Content>
        <Card.Header>{t('Category::Summary::Category preview')}</Card.Header>
        <List bulleted>
          <List.Item>{t('Category::Summary::Title: {{name}}', { name })}</List.Item>

          <List.Item>
            <Trans
              i18nKey={__('Category::Summary::Visibility: {{visibility}} <icon />')}
              values={{ visibility: form.get('visibility') }}
              components={{ icon: <Icon name={CATEGORY_VISIBILITY_ICONS[form.get('visibility')]} /> }}
            />
          </List.Item>

          <List.Item>
            {t('Category::Summary::Type: {{type}}', {
              type: form.get('readonly', false) ? t('Category::Summary::Immutable') : t('Category::Summary::Mutable'),
            })}
          </List.Item>

          <List.Item>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
              {t('Category::Summary::Color')}:{' '}
              <Label circular size='mini' style={{ backgroundColor: form.get('color') }} />
            </div>
          </List.Item>

          <List.Item>
            {t('Category::Summary::Description: {{description}}', {
              description: form.get('description'),
            })}
          </List.Item>

          <List.Item>
            {t('Category::Summary::Tags::Tags')}:{' '}
            <List>
              {tags?.map((tag) => (
                <List.Item key={tag} value='-'>
                  {tag}
                </List.Item>
              ))}
            </List>
          </List.Item>
        </List>
      </Card.Content>

      <ActionButtons category={category} name={name} onCategoryCreation={setCreatedCategoryId} />

      {(createError || updateError) && (
        <Card.Content>
          <ApiErrorMessage error={createError || updateError} />
        </Card.Content>
      )}
    </Card>
  )
}

_CategoryCard.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number,
  }),
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

_CategoryCard.defaultProps = {
  category: undefined,
}

export const CategoryCard = withRouter(_CategoryCard)
