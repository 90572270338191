import React from 'react'
import PropTypes from 'helpers/proptypes'
import { Message, Modal } from 'semantic-ui-react'

const commonPropTypes = {
  error: PropTypes.shape({
    data: PropTypes.shape({
      message: PropTypes.string,
      stack_trace: PropTypes.string,
    }),
    status: PropTypes.number,
    statusText: PropTypes.string,
  }),
  noStack: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func,
}

const commonDefaultProp = {
  error: undefined,
  modal: false,
  noStack: true,
  onDismiss: undefined,
}

function ErrorMessage({ error, noStack, onDismiss }) {
  return (
    <Message error onDismiss={onDismiss}>
      <Message.Header>{`${error.status} ${error.statusText}`}</Message.Header>
      {error.data && (
        <Message.Content>
          <h4>{error.data.message}</h4>
          {!noStack && <p>{error.data.stack_trace}</p>}
        </Message.Content>
      )}
    </Message>
  )
}

ErrorMessage.propTypes = {
  ...commonPropTypes,
  error: commonPropTypes.error.isRequired,
}

ErrorMessage.defaultProps = {
  onDismiss: commonDefaultProp.onDismiss,
}

function ApiErrorMessage({ error, modal, noStack, onDismiss }) {
  if (!error) return null
  const jsError = error.toJS ? error.toJS() : error

  if (modal)
    return (
      <Modal basic defaultOpen onClose={onDismiss}>
        <ErrorMessage error={jsError} noStack={noStack} />
      </Modal>
    )

  return <ErrorMessage error={jsError} noStack={noStack} onDismiss={onDismiss} />
}

ApiErrorMessage.propTypes = {
  ...commonPropTypes,
  modal: PropTypes.bool,
}

ApiErrorMessage.defaultProps = commonDefaultProp

export default ApiErrorMessage
