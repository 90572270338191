import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useKebabCase } from 'hooks/useKebabCase'
import { Trans } from 'react-i18next'
import { Message } from 'semantic-ui-react'

const MAX_LENGTH = 191

export function KebabCaseFormatMessage({ text }) {
  const { formattedText, isTextInKebabCase } = useKebabCase(text, MAX_LENGTH)

  if (!isTextInKebabCase && text?.length <= MAX_LENGTH) return null

  return (
    <React.Fragment>
      {isTextInKebabCase && (
        <Message
          info
          compact
          icon='info circle'
          content={
            <Trans
              i18nKey={__('KebabCaseFormatMessage::Summary::Will be formatted as <b>{{formattedText}}</b>')}
              values={{
                formattedText,
              }}
              components={{ b: <b /> }}
            />
          }
        />
      )}
      {text?.length > MAX_LENGTH && (
        <Message
          warning
          compact
          icon='info warning sign'
          content={
            <Trans
              i18nKey={__(
                'KebabCaseFormatMessage::Summary::<b>{{formattedText}}</b> exceeds the maximum length of {{count}} characters and will be truncated.',
              )}
              values={{
                count: MAX_LENGTH,
                formattedText,
              }}
              components={{ b: <b /> }}
            />
          }
        />
      )}
    </React.Fragment>
  )
}

KebabCaseFormatMessage.propTypes = {
  text: PropTypes.string,
}

KebabCaseFormatMessage.defaultProps = {
  text: '',
}
