import React, { useEffect, useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { getCategoryWithTags } from 'redux/entities/selectors'
import { fetchCategory } from 'redux/entities/actions'
import { Grid } from 'semantic-ui-react'
import EditPageMenu from 'components/menus/EditPageMenu'
import { CategoryForm } from './CategoryForm'
import { CategoryCard } from './CategoryCard'

export function CategoryFormWrapper({ params: { id } }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const category = useSelector((state) => getCategoryWithTags(state, { id }))

  const menuItems = useMemo(
    () => [
      { text: t('Category::Summary::Name'), value: 'name' },
      { text: t('Category::Summary::Visibility'), value: 'visibility' },
      { text: t('Category::Summary::Type'), value: 'readonly' },
      { text: t('Category::Summary::Color'), value: 'color' },
      { text: t('Category::Summary::Description'), value: 'description' },
      { text: t('Category::Summary::Tags::Tags'), value: 'tags' },
    ],
    [t],
  )

  useEffect(() => {
    if (category.id) dispatch(fetchCategory(id))
  }, [dispatch, id, category.id])

  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column width={3}>
          <EditPageMenu listName='categories' menuItems={menuItems} />
        </Grid.Column>
        <Grid.Column width={8}>
          <CategoryForm category={category} />
        </Grid.Column>
        <Grid.Column width={3}>
          <CategoryCard category={category} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

CategoryFormWrapper.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
}
