import { Map, List } from 'immutable'
import { recordsByEntities } from 'redux/entities/schemas'

export const getCollection = (state) => state.get('entities')

export const isFetching = (url) => (state) => getCollection(state).hasIn(['loading', `GET ${url}`])
export const isUpdating = (url) => (state) => getCollection(state).hasIn(['loading', `PUT ${url}`])
export const isDeleting = (url) => (state) => getCollection(state).hasIn(['loading', `DELETE ${url}`])
export const isPatching = (url) => (state) => getCollection(state).hasIn(['loading', `PATCH ${url}`])
export const isCreating = (url) => (state) => getCollection(state).hasIn(['loading', `POST ${url}`])

export const getEntities = (name) => (state) => getCollection(state).getIn(['entities', name], new Map())

export const getFetchingError = (url) => (state) => getCollection(state).getIn(['errors', `GET ${url}`])
export const getUpdatingError = (url) => (state) => getCollection(state).getIn(['errors', `PUT ${url}`])
export const getDeletingError = (url) => (state) => getCollection(state).getIn(['errors', `DELETE ${url}`])
export const getPatchingError = (url) => (state) => getCollection(state).getIn(['errors', `PATCH ${url}`])
export const getCreatingError = (url) => (state) => getCollection(state).getIn(['errors', `POST ${url}`])
export const getHeadError = (url) => (state) => getCollection(state).getIn(['errors', `HEAD ${url}`])

export const getError = (url) => (state) =>
  getFetchingError(url)(state) ||
  getUpdatingError(url)(state) ||
  getCreatingError(url)(state) ||
  getDeletingError(url)(state) ||
  getPatchingError(url)(state) ||
  getHeadError(url)(state)

export const getEntity =
  (name) =>
  (state, { id }) =>
    getEntities(name)(state).get(String(id), new recordsByEntities[name]())
export const getEntityCount = (name) => (state) => getEntities(name)(state).size

export const getEntityOrder = (name) => (state) => getCollection(state).getIn(['metadata', name, 'order'], new List())
export const getEntityTotal = (name) => (state) => getCollection(state).getIn(['metadata', name, 'count'], 0)
