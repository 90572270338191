import React, { useEffect, useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { Form, Message } from 'semantic-ui-react'
import { KebabCaseFormatMessage } from 'components/forms/categories'
import { createForm, removeForm, mergeInForm } from 'redux/forms/actions'
import { getForm } from 'redux/forms/reducer'
import { getCategories, getCategory } from 'redux/entities/selectors'

import './index.css'

export function TagForm({ categoryId, disableCategorySelection, tag }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const categories = useSelector(getCategories)
  const form = useSelector((state) => getForm(state, 'tag'))
  const category = useSelector((state) => getCategory(state, { id: form.get('category_id') }))

  useEffect(() => {
    dispatch(
      createForm({
        formName: 'tag',
        initialState: {
          title: tag.title,
          category_id: categoryId || tag.category_id,
          description: tag.description,
        },
      }),
    )
    return () => dispatch(removeForm({ formName: 'tag' }))
  }, [dispatch, tag.category_id, tag.title, tag.description, categoryId])

  const options = useMemo(
    () =>
      categories
        .toList()
        .sortBy((category) => category.name)
        .map(({ id, name }) => ({ value: id, text: name }))
        .toJS(),
    [categories],
  )

  function handleChange(_, { value, name }) {
    dispatch(mergeInForm({ formName: 'tag', value: { [name]: value } }))
  }

  return (
    <Form className='vz-tag-add-form'>
      <section id='title'>
        <h3 className='section-title'>{t('Tags::Summary::Title')}</h3>
        <Form.Group>
          <Form.Field width={6}>
            <Form.Input name='title' value={form.get('title', '')} onChange={handleChange} />
          </Form.Field>
          <Form.Field width={10}>
            <KebabCaseFormatMessage text={form.get('title')} />
          </Form.Field>
        </Form.Group>
      </section>
      <section id='category'>
        <h3 className='section-title'>{t('Tags::Summary::Category')}</h3>
        <Form.Group>
          <Form.Field width={6} disabled={disableCategorySelection}>
            <Form.Select
              name='category_id'
              value={form.get('category_id', '')}
              onChange={handleChange}
              search
              options={options}
            />
          </Form.Field>
          <Form.Field width={10}>
            {category.get('readonly', false) && (
              <Message
                info={!tag.id}
                warning={!!tag.id}
                icon={tag.id ? 'warning sign' : 'info circle'}
                compact
                content={
                  <Trans
                    i18nKey={__(
                      'Tags::Summary::<b>{{categoryName}}</b> is a readonly category. Tags with readonly categories cannot be updated, archived or unarchived.',
                    )}
                    values={{
                      categoryName: category.get('name'),
                    }}
                    components={{ b: <b /> }}
                  />
                }
              />
            )}
          </Form.Field>
        </Form.Group>
      </section>
      <section id='description'>
        <h3 className='section-title'>{t('Tags::Summary::Description')}</h3>
        <Form.TextArea name='description' value={form.get('description', '')} onChange={handleChange} />
      </section>
    </Form>
  )
}

TagForm.propTypes = {
  categoryId: PropTypes.number,
  disableCategorySelection: PropTypes.bool,
  tag: PropTypes.shape({
    id: PropTypes.number,
    category_id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
}

TagForm.defaultProps = {
  categoryId: undefined,
  disableCategorySelection: false,
}
