import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFetchPaginatedResults } from '../../hooks/pagination/useFetchPaginatedResults'
import { List, Paginate } from 'components/lists'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { CategoriesRow } from './CategoriesRow'
import { getLocation } from 'redux/reducers/router'
import {
  getCategoriesWithTags,
  getCategoriesError,
  fetchingCategories,
  getCategoriesTotal,
} from 'redux/entities/selectors'

export function CategoriesList() {
  const { t } = useTranslation()
  useFetchPaginatedResults('categories')

  const error = useSelector(getCategoriesError)
  const loading = useSelector(fetchingCategories)
  const categories = useSelector(getCategoriesWithTags)
  const total = useSelector(getCategoriesTotal)
  const location = useSelector(getLocation)

  const headers = useMemo(
    () => [
      { label: t('Categories::Id'), field: 'id' },
      { label: t('Categories::Name'), field: 'name' },
      { label: t('Categories::Visibility') },
      { label: t('Categories::Type') },
      { label: t('Categories::Number of tags') },
      { label: t('Categories::Archived') },
      { label: t('Categories::Actions') },
    ],
    [t],
  )

  return (
    <Paginate count={total} previous next loading={loading}>
      <List striped celled sortable collapsing headers={headers} location={location}>
        {categories.toList().map((category) => (
          <CategoriesRow key={category.id} category={category} />
        ))}
      </List>
      <ApiErrorMessage error={error} modal />
    </Paginate>
  )
}
