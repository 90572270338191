import React, { useMemo, useState, useEffect, useCallback } from 'react'
import PropTypes from 'helpers/proptypes'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { Button, Icon, Modal, Card, Segment, Label, Popup } from 'semantic-ui-react'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { TagForm } from '../../tags/edit/Form'
import { FormActionsButtons } from '../../tags/edit/FormActionsButtons'
import {
  getArchiveTagError,
  getUnarchiveTagError,
  getCreateTagError,
  getUpdateTagError,
  archivingTag,
  unarchivingTag,
} from 'redux/entities/selectors'
import { clearTagErrors, fetchCategory } from 'redux/entities/actions'
import { emptyTag } from 'redux/entities/schemas/tags'
import { useHasLoadingSucceeded } from 'hooks'

const initialState = { tagId: undefined, showModal: false }

export function TagsSection({ category }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [{ tagId, showModal }, setState] = useState(initialState)
  const tag = useMemo(() => category.tags.find((tag) => tag.id === tagId) || emptyTag, [category.tags, tagId])

  const isArchivingTag = useSelector((state) => archivingTag(state, tagId))
  const archiveTagError = useSelector((state) => getArchiveTagError(state, tagId))
  const isUnarchivingTag = useSelector((state) => unarchivingTag(state, tag.id))
  const unarchiveTagError = useSelector((state) => getUnarchiveTagError(state, tagId))
  const createTagError = useSelector(getCreateTagError)
  const updateTagError = useSelector((state) => getUpdateTagError(state, tagId))
  const hasTagBeenArchived = useHasLoadingSucceeded(isArchivingTag, archiveTagError)
  const hasTagBeenUnarchived = useHasLoadingSucceeded(isUnarchivingTag, unarchiveTagError)

  const handleModalClose = useCallback(async () => {
    await dispatch(clearTagErrors())
    setState(initialState)
  }, [dispatch])

  async function hanldeTagCreation() {
    await handleModalClose()
    dispatch(fetchCategory(category.id))
  }

  useEffect(() => {
    if (hasTagBeenArchived || hasTagBeenUnarchived) handleModalClose()
  }, [handleModalClose, hasTagBeenArchived, hasTagBeenUnarchived])

  async function handleModalOpen(e, tagId) {
    if (category.readonly) return e.preventDefault()
    await dispatch(clearTagErrors())
    setState({ showModal: true, tagId })
  }

  return (
    <React.Fragment>
      <Card.Group>
        {category.tags.map((tag) => (
          <Card key={tag.get('id')}>
            <Card.Content>
              <Segment floated='right' circular textAlign='center' style={{ padding: '8px' }}>
                <Icon name='tag' style={{ margin: '0' }} />
              </Segment>
              <Card.Header>{tag.get('title')}</Card.Header>
              {tag.get('archived_at') && (
                <Card.Meta>
                  <Label color='red'>
                    <Icon name='archive' /> {t('Category::Summary::Tags::Archived')}
                  </Label>
                </Card.Meta>
              )}
              <Card.Description>
                <Trans
                  i18nKey={
                    tag.get('description')
                      ? __('Category::Summary::Tags::Description: {{description}}')
                      : __('Category::Summary::Tags::<i>No description provided.</i>')
                  }
                  values={{ description: tag.get('description') }}
                  components={{ i: <i /> }}
                />
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              <Popup
                trigger={
                  <Button primary fluid onClick={(_) => handleModalOpen(_, tag.get('id'))}>
                    {t('Category::Summary::Tags::Edit')}
                  </Button>
                }
                disabled={!category.readonly}
                content={t('Category::Summary::Tags::Immutable category tags cannot be edited.')}
                position='top left'
              />
            </Card.Content>
          </Card>
        ))}
        <Card>
          <Card.Content textAlign='center'>
            <Card.Description>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  alignItems: 'center',
                  marginTop: '16px',
                }}
              >
                <Icon name='tag' circular />
                <b style={{ fontSize: '18px' }}>{t('Category::Summary::Tags::Add new tag')}</b>
              </div>
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Popup
              trigger={
                <Button basic color='blue' fluid onClick={handleModalOpen}>
                  {t('Category::Summary::Tags::Create')}
                </Button>
              }
              disabled={!category.readonly}
              content={t("Category::Summary::Tags::You can't link tags to immutable categories.")}
              position='top left'
            />
          </Card.Content>
        </Card>
      </Card.Group>
      <Modal closeIcon onClose={handleModalClose} open={showModal}>
        <Modal.Content>
          <TagForm tag={tag} disableCategorySelection categoryId={category.id} />
          <ApiErrorMessage error={archiveTagError || unarchiveTagError || createTagError || updateTagError} />
        </Modal.Content>
        <FormActionsButtons
          onTagCreation={hanldeTagCreation}
          onTagUpdate={handleModalClose}
          tag={tag}
          fluidButtons={false}
          Wrapper={Modal.Actions}
        />
      </Modal>
    </React.Fragment>
  )
}

TagsSection.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number,
    readonly: PropTypes.boolean,
    tags: PropTypes.immutable.list,
  }).isRequired,
}
