import React, { useState, useMemo, useCallback, useEffect } from 'react'
import PropTypes from 'helpers/proptypes'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { withRouter, Link } from 'react-router'
import { usePrevious } from 'hooks/usePrevious'
import { Form, Button, Checkbox } from 'semantic-ui-react'
import { SearchInput, SelectInput } from 'components/inputs'
import { getLocation } from 'redux/reducers/router'
import { getCategories } from 'redux/entities/selectors'

const INITIAL_STATE = {
  search: undefined,
  category: undefined,
  showArchivedOnly: undefined,
}

function _TagsListSearchForm({ router }) {
  const { t } = useTranslation()

  const location = useSelector(getLocation)
  const prevLocation = usePrevious(location)
  const categories = useSelector(getCategories)

  const [state, setState] = useState(INITIAL_STATE)

  const categoryOptions = useMemo(() => {
    return categories
      .toList()
      .sortBy((category) => category.get('name'))
      .map(({ name }) => ({ text: name, value: name }))
      .toJS()
  }, [categories])

  const updateStateFromLocation = useCallback(() => {
    setState({
      search: location.getIn(['query', 'search'], undefined),
      category: location.getIn(['query', 'category'], undefined),
      showArchivedOnly: location.getIn(['query', 'showArchivedOnly'], undefined),
    })
  }, [location])

  useEffect(() => {
    if (!location.equals(prevLocation)) updateStateFromLocation()
  }, [location, prevLocation, updateStateFromLocation])

  function updateQuery(query = state) {
    router.push(location.mergeIn(['query'], { ...query, offset: 0 }).toJS())
  }

  function clearFilters() {
    setState(INITIAL_STATE)
    updateQuery(INITIAL_STATE)
  }

  function clearSorting() {
    router.push(location.mergeIn(['query'], { sortBy: '', order: '' }).toJS())
  }

  function handleSubmit(e) {
    e.preventDefault()
    updateQuery()
  }

  return (
    <Form onSubmit={handleSubmit} style={{ marginBottom: '1em' }} autoComplete='off'>
      <Form.Group>
        <Form.Field>
          <label>{t('Tags::Search by tag id or title')}</label>
          <SearchInput
            placeholder={t('Tags::Title')}
            fluid
            name='search'
            icon='search'
            iconPosition='left'
            value={state.search || ''}
            onChange={(search) => setState((prevState) => ({ ...prevState, search }))}
          />
        </Form.Field>

        <Form.Field>
          <label>{t('Tags::Category')}</label>
          <SelectInput
            queryName='category'
            search
            options={categoryOptions}
            value={state.category || ''}
            onChange={(category) => setState((prevState) => ({ ...prevState, category: category.join(',') }))}
            multiple
            preventNavigation
          />
        </Form.Field>

        <Form.Field>
          <label>{t('Tags::Show archived tags')}</label>
          <Checkbox
            onChange={(_, { checked }) => setState((prevState) => ({ ...prevState, showArchivedOnly: checked }))}
            checked={String(state.showArchivedOnly) === 'true'}
          />
        </Form.Field>

        <Form.Field style={{ display: 'flex', alignItems: 'flex-end', gap: '24px' }}>
          <label>&nbsp;</label>
          <Button type='submit'>{t('Tags::Search')}</Button>

          <Button.Group vertical basic compact size='small'>
            <Button basic onClick={clearFilters}>
              {t('Tags::Clear filters')}
            </Button>
            <Button onClick={clearSorting}>{t('Tags::Clear sorting')}</Button>
          </Button.Group>

          <Button as={Link} to='/tags/add' primary>
            {t('Tags::Create new Tag')}
          </Button>

          <Button as={Link} to='/categories/add' primary>
            {t('Tags::Create new Category')}
          </Button>
        </Form.Field>
      </Form.Group>
    </Form>
  )
}

_TagsListSearchForm.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

export const TagsListSearchForm = withRouter(_TagsListSearchForm)
