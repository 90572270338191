import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useHasLoadingSucceeded } from 'hooks'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCategory,
  archivingTag,
  unarchivingTag,
  getArchiveTagError,
  getUnarchiveTagError,
} from 'redux/entities/selectors'
import { getLocation } from 'redux/reducers/router'
import { archiveTag, unarchiveTag, fetchTags, clearTagErrors } from 'redux/entities/actions'
import { Link } from 'react-router'
import { Table, Button, Popup } from 'semantic-ui-react'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { Tooltip } from 'components/tooltips/Tooltip'
import { omitBy, isNil } from 'lodash'
import moment from 'moment'
import { toast } from 'react-toastify'

function ArchiveButton({ tag }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const category = useSelector((state) => getCategory(state, { id: tag.category_id }))

  if (tag.archived_at)
    return (
      <Button color='green' compact onClick={() => dispatch(unarchiveTag(tag.id))}>
        {t('Tags::Unarchive')}
      </Button>
    )

  if (category.readonly)
    return (
      <Popup
        trigger={<Button compact>{t('Category::Archive')}</Button>}
        content={t('Category::Tags belonging to immutable or archived categories cannot be archived')}
        position='top left'
      />
    )

  return (
    <Button color='red' compact onClick={() => dispatch(archiveTag(tag.id))}>
      {t('Tags::Archive')}
    </Button>
  )
}

ArchiveButton.propTypes = {
  tag: PropTypes.shape({
    id: PropTypes.number,
    archived_at: PropTypes.string,
    category_id: PropTypes.number,
  }).isRequired,
}

function EditButton({ tag }) {
  const { t } = useTranslation()
  const category = useSelector((state) => getCategory(state, { id: tag.category_id }))

  if (category.readonly || !!category.archived_at)
    return (
      <Popup
        trigger={<Button compact>{t('Tags::Edit')}</Button>}
        content={t('Category::Tags belonging to immutable or archived categories cannot be updated')}
        position='top left'
      />
    )

  return (
    <Button primary compact as={Link} to={`tags/edit/${tag.id}`}>
      {t('Tags::Edit')}
    </Button>
  )
}

EditButton.propTypes = {
  tag: PropTypes.shape({
    id: PropTypes.number,
    archived_at: PropTypes.string,
    category_id: PropTypes.number,
  }).isRequired,
}

export function TagsRow({ tag }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const category = useSelector((state) => getCategory(state, { id: tag.category_id }))
  const location = useSelector(getLocation)
  const isArchivingTag = useSelector((state) => archivingTag(state, tag.id))
  const isUnarchivingTag = useSelector((state) => unarchivingTag(state, tag.id))
  const archiveTagError = useSelector((state) => getArchiveTagError(state, tag.id))
  const unarchiveTagError = useSelector((state) => getUnarchiveTagError(state, tag.id))
  const isTagArchived = useHasLoadingSucceeded(isArchivingTag, archiveTagError)
  const isTagUnarchived = useHasLoadingSucceeded(isUnarchivingTag, unarchiveTagError)

  useEffect(() => {
    if (isTagArchived || isTagUnarchived) {
      const query = omitBy(location.get('query').toJS(), isNil())
      dispatch(fetchTags({ query }))
    }
  }, [dispatch, isTagArchived, isTagUnarchived, location])

  useEffect(() => {
    if (isTagArchived || isTagUnarchived) {
      toast.success(
        `${
          isTagArchived
            ? t('ToastNotification::The tag has been archived')
            : t('ToastNotification::The tag has been unarchived')
        } 👍`,
        { type: toast.TYPE.SUCCESS },
      )
    }
  }, [isTagArchived, isTagUnarchived, t])

  return (
    <React.Fragment>
      <Table.Row>
        <Table.Cell>{tag.id}</Table.Cell>
        <Table.Cell>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
            {category.name}
            <Tooltip description={category.description} visibility={category.visibility} />
          </div>
        </Table.Cell>
        <Table.Cell>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
            {tag.title}
            <Tooltip description={tag.description} />
          </div>
        </Table.Cell>
        <Table.Cell>{tag.archived_at && moment.utc(tag.archived_at).format('YYYY-MM-DD')}</Table.Cell>
        <Table.Cell>
          <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            <EditButton tag={tag} />
            <ArchiveButton tag={tag} />
          </div>
        </Table.Cell>
      </Table.Row>
      {(archiveTagError || unarchiveTagError) && (
        <ApiErrorMessage
          error={archiveTagError || unarchiveTagError}
          modal
          onDismiss={() => dispatch(clearTagErrors())}
        />
      )}
    </React.Fragment>
  )
}

TagsRow.propTypes = {
  tag: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    category_id: PropTypes.number,
    archived_at: PropTypes.string,
  }).isRequired,
}
